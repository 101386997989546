import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import PageBanner from '../components/PageBanner';


const Article = ({ darkMode, article }) => {
	const [imageLoaded, setImageLoaded] = useState(false);
	const handleImageLoad = () => {
		setImageLoaded(true);
	};

	const [scrollDown, setScrollDown] = useState(0);
	useEffect(() => {
		const handleScroll = () => {
			setScrollDown((window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<motion.div
			className='grid grid-cols-1 gap-8 md:gap-12'
			// initial={{ opacity: 0 }}
			// animate={{ opacity: 1 }}
			// exit={{ opacity: 0 }}
			// transition={{ duration: 0.3 }}
		>
			<PageBanner image={article.image} scrollDown={scrollDown} blur={true} />

			<motion.div
				className='grid grid-cols-1 w-full gap-4 md:gap-8 mx-auto px-2 md:px-4 z-10'
				// initial={{ opacity: 0, y: 20 }}
				// animate={{ opacity: 1, y: 0 }}
			>
				<div className='w-full grid grid-cols-1 justify-start items-start gap-8'>
					{article.content}
				</div>
			</motion.div>
		</motion.div>
	);
};

export default Article;
