import React from "react";
import { Link } from "react-router-dom";
import MediaLoader from "./MediaLoader";


const CardContents = ({ link, thumbnail, title, subtitle, icon }) => {
	return (
		<>
			<div
				className="overflow-hidden w-full"
			>
				<MediaLoader
					src={thumbnail} alt={link}
					thumb={true}
					type={'image'}
					className={`w-full group-hover:brightness-75`}
					imgClassName={`
						w-full aspect-[3/1] md:aspect-[2/1] object-cover object-top shadow
						transition-all ease-in-out overflow-hidden
					`}
				/>
			</div>

			<div
				className='
					w-full absolute bottom-0 flex justify-between 
					backdrop-blur p-2 
					dark:group-hover:bg-black group-hover:bg-neutral-50
					font-bold text-xs md:text-sm transition-all ease-in-out
				'
			>
				<h2 className='transition-all ease-in-out flex gap-1 items-center'>
					<span className=''>{icon && icon}</span>
					<span>
						{title}
					</span>
				</h2>
				<h2 className='transition-all ease-in-out'>
					{subtitle}
				</h2>
			</div>
		</>
	);
};


const ContactCard = ({ link, target, thumbnail, title, subtitle, internal, icon }) => {
	return (
		<>
			{internal ?
				<Link to={link} className='group overflow-hidden relative'>
					<CardContents link={link} thumbnail={thumbnail} title={title} subtitle={subtitle} />
				</Link>
				:
				<a href={link} target={target} rel='noreferrer' className='group overflow-hidden relative'>
					<CardContents link={link} thumbnail={thumbnail} title={title} subtitle={subtitle} icon={icon} />
				</a >
			}
		</>
	);
}

export default ContactCard;
