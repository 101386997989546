import React from "react";


const PageHeader = ({ title }) => {
	return (
		<h3 className="font-extrabold text-2xl w-full mx-auto z-20 text-balance">
			{title}
		</h3>
	);
}

export default PageHeader;
