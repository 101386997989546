import React, { useState } from 'react';
import ImageSlider from '../components/ImageSlider';


const ReservationText = `
𝗨𝗡𝗗𝗼𝗫 예약 양식

• 원하시는 촬영 날짜/시간: 
• 촬영 용도: [아티스트 프로필, 앨범커버, 일반 프로필 등]
• 촬영 컨셉 수:
• 촬영 인원:
• 동행 인원:
• 헤어/메이크업 필요 여부: 
• 기타 요청 및 문의 사항:

• 원하시는 촬영 레퍼런스 사진 및 분위기 설명
• 신발 포함한 착장 사진
`;

const CopyToClipboard = () => {
	const [Copied, setCopied] = useState(false);

	const handleCopy = () => {
		navigator.clipboard.writeText(ReservationText).then(() => {
			setCopied(true);
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}).catch((error) => {
			console.error('Error copying text: ', error);
		});
	};

	return (
		<button
			onClick={handleCopy}
			className='w-fit submit-button'
		>
			{Copied ? '복사 완료!' : '양식 복사하기'}
		</button>
	);
};


const Notices = [
	{
		"id": "01",
		"title": "Pricing",
		"subtitle": "개인 프로필",
		"image": `${process.env.PUBLIC_URL}/assets/system/sys_22.webp`,
		"content": (
			<>
				<div className='grid grid-cols-1 justify-start items-start gap-4'>
					<div className='text-contents grid grid-cols-1 gap-4'>
						<h3 className='font-bold text-lg'>개인 프로필 예약</h3>

						<h3 className='font-bold'>예약 양식</h3>
						<ul className='list-disc list-inside pl-4'>
							<li>원하시는 촬영 날짜/시간: </li>
							<li>촬영 용도:
								<span className='italic opacity-50 ml-2'>아티스트 프로필, 앨범커버, 일반 프로필 등</span>
							</li>
							<li>촬영 컨셉 수: </li>
							<li>촬영 인원: </li>
							<li>동행 인원: </li>
							<li>원하시는 촬영 레퍼런스 및 분위기</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>저희 사진이 아니여도 괜찮으니 편하게 캡쳐나 사진과 함께 텍스트 설명 부탁드립니다.</li>
							</ul>
							<li>신발 포함한 착장 사진</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>미정인 경우 컨셉 확정 후 보내주셔도 됩니다. </li>
							</ul>
							<li>헤어/메이크업 필요 여부: </li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>저희 통해서 헤어/메이크업 요청 시 출장 헤메 아티스트 금액 추가됩니다. </li>
								<li>개인적으로 받고 오시는 경우 알려주세요. </li>
							</ul>
							<li>기타 요청 및 문의 사항: </li>
						</ul>

						<CopyToClipboard />
					</div>

					<div className='text-contents grid grid-cols-1 gap-4'>
						<h3 className='font-bold text-lg'>개인 프로필 촬영 공지</h3>

						<h3 className='font-bold'>촬영장 공지</h3>
						<ul className='list-disc list-inside pl-4'>
							<li>스튜디오 내에 기본 최대 3인(촬영자 포함) 동행 가능</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>사전에 꼭 말씀해주세요!</li>
							</ul>
							<li>인원/반려 동물 추가 시 추가금 발생</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>추가 인원 당 50,000</li>
							</ul>
						</ul>

						<h3 className='font-bold'>촬영/보정 공지</h3>
						<ul className='list-disc list-inside pl-4'>
							<li>컨셉 연출 난이도에 따라 추가금 발생 가능</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>기본 장비 외 추가 장비 필요 시 대여료 발생</li>
								<li>기본 스튜디오 외 렌탈 스튜디오 필요 시 대여료 발생</li>
							</ul>
							<li>헤어/메이크업 미포함</li>
							<li>최종본 전달 셀렉 완료 날짜로부터 최소 7일 ~ 최대 3주</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>작업 기간 3일 이내로 단축 단축 장 당 30,000</li>
							</ul>
							<li>최종 보정 장수 추가 장당 30,000</li>
							<li>원본(1차 색감 보정본) 컨셉 당 20~30장 50,000</li>
							<li>보정 수정 기본 1회</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>수정 추가 장당 5,000</li>
							</ul>
							<li>보정본과 촬영 현장 이미지 및 영상은 마케팅 용도로 활용될 수 있음</li>
						</ul>
					</div>
				</div>

				<div className='grid grid-cols-1 justify-start items-start'>
					<ImageSlider selectedTags={['Studio']} leftoutTags={['Graphics', 'Lookbook']} />
					<div className='text-contents grid grid-cols-1 lg:grid-cols-2 gap-8'>
						<div className='grid grid-cols-1 gap-4'>
							<h3 className='font-bold text-lg'>스튜디오 프로필</h3>
							<ul className='list-disc list-inside pl-4'>
								<li>컨셉 1개 200,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>착장 1개</li>
										<li>촬영시간 1시간 이내</li>
										<li>최종 보정본 4장 제공</li>
									</ul>
								</li>
								<li>컨셉 2개 300,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>착장 2개</li>
										<li>촬영시간 2시간 이내</li>
										<li>최종 보정본 6장 제공</li>
									</ul>
								</li>
								<li>컨셉 3개 370,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>착장 3개</li>
										<li>촬영시간 3시간 이내</li>
										<li>최종 보정본 9장 제공</li>
									</ul>
								</li>
								<li>컨셉 4개 이상 / 촬영시간 3시간 이상 별도 문의</li>
							</ul>
						</div>

						<table>
							<thead>
								<tr>
									<th>Package</th>
									<th>착장</th>
									<th>촬영시간</th>
									<th>최종 보정본</th>
									<th>가격</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>컨셉 1개</td>
									<td>1개</td>
									<td>1시간 이내</td>
									<td>4장</td>
									<td>200,000</td>
								</tr>
								<tr>
									<td>컨셉 2개</td>
									<td>2개</td>
									<td>2시간 이내</td>
									<td>6장</td>
									<td>300,000</td>
								</tr>
								<tr>
									<td>컨셉 3개</td>
									<td>3개</td>
									<td>3시간 이내</td>
									<td>9장</td>
									<td>370,000</td>
								</tr>
								<tr>
									<td>컨셉 4개+</td>
									<td colSpan={4}>별도문의</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className='grid grid-cols-1 justify-start items-start'>
					<ImageSlider selectedTags={['Street']} leftoutTags={['Graphics', 'Lookbook']} />
					<div className='text-contents grid grid-cols-1 lg:grid-cols-2 gap-8'>
						<div className='grid grid-cols-1 gap-4'>
							<h3 className='font-bold text-lg'>야외 스냅/프로필</h3>
							<ul className='list-disc list-inside pl-4'>
								<li>기본 1시간 이내 150,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>착장 1개</li>
										<li>최종 보정본 3장 제공</li>
									</ul>
								</li>
								<li>2시간 200,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>착장 최대 2개</li>
										<li>최종 보정본 5장 제공</li>
									</ul>
								</li>
								<li>2시간 초과 별도 문의</li>
							</ul>
						</div>

						<table>
							<thead>
								<tr>
									<th>Duration</th>
									<th>착장</th>
									<th>최종 보정본</th>
									<th>가격</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1시간 이내</td>
									<td>1개</td>
									<td>3장</td>
									<td>150,000</td>
								</tr>
								<tr>
									<td>2시간 이내</td>
									<td>2개</td>
									<td>5장</td>
									<td>200,000</td>
								</tr>
								<tr>
									<td>2시간 초과</td>
									<td colSpan={3}>별도 문의</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className='grid grid-cols-1 justify-start items-start'>
					<ImageSlider selectedTags={['Graphics']} leftoutTags={[]} />
					<div className='text-contents grid grid-cols-1 gap-4'>
						<h3 className='font-bold text-lg'>디자인 공지</h3>

						<ul className='list-disc list-inside pl-4'>
							<li>앨범커버 등 디자인 별도 문의</li>
							<ul className='list-[circle] list-inside pl-4'>
								<li>타이포 디자인</li>
								<li>3D 아트워크/비주얼라이저</li>
								<li>일러스트</li>
							</ul>
						</ul>
					</div>
				</div>
			</>
		)
	},
	{
		"id": "02",
		"title": "Pricing",
		"subtitle": "룩북",
		"image": `${process.env.PUBLIC_URL}/assets/system/sys_33.webp`,
		"content": (
			<>
				<div className='grid grid-cols-1 justify-start items-start'>
					<ImageSlider selectedTags={['Lookbook']} leftoutTags={[]} />
					<div className='text-contents grid grid-cols-1 md:grid-cols-2 gap-8'>
						<div className='grid grid-cols-1 gap-4'>
							<h3 className='font-bold text-lg'>룩북</h3>
							<ul className='list-disc list-inside pl-4'>
								<li>1 ~ 10 착장 300,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>기본 3시간</li>
										<li>웹용 색보정 파일</li>
									</ul>
								</li>
								<li>11 ~ 15 착장 390,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>기본 4시간</li>
										<li>웹용 색보정 파일</li>
									</ul>
								</li>
								<li>16 ~ 20 착장 580,000
									<ul className='list-[circle] list-inside pl-4'>
										<li>기본 6시간</li>
										<li>웹용 색보정 파일</li>
									</ul>
								</li>
							</ul>
						</div>

						<table>
							<thead>
								<tr>
									<th>착장</th>
									<th>1 ~ 10</th>
									<th>11 ~ 15</th>
									<th>16 ~ 20</th>
									<th>21+</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>촬영 시간</td>
									<td>기본 3시간</td>
									<td>기본 4시간</td>
									<td>기본 6시간</td>
									<td>별도 문의</td>
								</tr>
								<tr>
									<td>가격</td>
									<td>300,000</td>
									<td>390,000</td>
									<td>580,000</td>
									<td>별도 문의</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className='text-contents grid grid-cols-1 gap-4 justify-start items-start'>
					<h3 className='font-bold text-lg'>룩북 기본 공지</h3>

					<ul className='list-disc list-inside pl-4'>
						<li>착장 수 사전 협의 후 진행</li>
						<li>색감/톤 수정 기본 2회
							<ul className='list-[circle] list-inside pl-4'>
								<li>수정 추가 시 20,000</li>
								<li>수정 추가 난이도에 따라 추가금 발생 가능</li>
							</ul>
						</li>
						<li>보정
							<ul className='list-[circle] list-inside pl-4'>
								<li>자세/옷 잡티 등 기본 포토샵 보정 장당 10,000</li>
								<li>모델/세부 보정은 자세/옷 잡티 보정에서 추가로 장당 5,000 추가</li>
								<li>수정 기본 2회</li>
								<li>수정 추가 시 장당 10,000</li>
							</ul>
						</li>
						<li>위 가격 기본 호리존 배경/조명 기준</li>
						<li>모델, 헤어/메이크업 필요 시 문의</li>
						<li>야외 촬영 별도 문의</li>
					</ul>
				</div>
			</>
		)
	},
	{
		"id": "03",
		"title": "Pricing",
		"subtitle": "제품",
		"image": `${process.env.PUBLIC_URL}/assets/system/sys_32.webp`,
		"content": (
			<>
				<div className='grid grid-cols-1 gap-4 justify-start items-start'>
					<div className='text-contents grid grid-cols-1 md:grid-cols-2 gap-4'>
						<div className='grid grid-cols-1 gap-4'>
							<h3 className='font-bold text-lg'>제품</h3>
							<div className='grid grid-cols-1 gap-4'>
								<h4 className="font-bold">기본컷</h4>
								<ul className='list-disc list-inside pl-4'>
									<li>장당 10,000</li>
									<li>기본 단색 배경</li>
									<li>리터칭, 톤보정 포함</li>
									<li>누끼 미포함</li>
								</ul>

								<h4 className="font-bold">누끼컷</h4>
								<ul className='list-disc list-inside pl-4'>
									<li>장당 15,000</li>
									<li>메인 컷 외 다른 각도 필요한 경우 컷당 추가금 발생</li>
									<li>누끼, 리터칭, 톤보정 포함</li>
								</ul>

								<h4 className="font-bold">연출컷</h4>
								<ul className='list-disc list-inside pl-4'>
									<li>장당 35,000</li>
									<li>연출 난이도에 따라 견적 달라질 수 있습니다.
										<ul className='list-[circle] list-inside pl-4'>
											<li>반사, 투명, 구김이 있는 제품 경우</li>
											<li>합성 및 특수촬영이 필요한 경우</li>
										</ul>
									</li>
									<li>리터칭, 톤보정 포함</li>
								</ul>
							</div>
						</div>

						<table>
							<thead>
								<tr>
									<th></th>
									<th>기본</th>
									<th>누끼</th>
									<th>연출</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>장당</td>
									<td>10,000</td>
									<td>15,000</td>
									<td>35,000</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div className='text-contents grid grid-cols-1 gap-4'>
					<h4 className="font-bold">타임</h4>
					<ul className='list-disc list-inside pl-4'>
						<li>3시간 350,000
							<ul className='list-[circle] list-inside pl-4'>
								<li>컷수, 컨셉 제한 없이 자유롭게 촬영</li>
								<li>모든 컷 리터칭, 톤보정 포함</li>
							</ul>
						</li>
						<li>6시간 650,000
							<ul className='list-[circle] list-inside pl-4'>
								<li>컷수, 컨셉 제한 없이 자유롭게 촬영</li>
								<li>모든 컷 리터칭, 톤보정 포함</li>
							</ul>
						</li>
					</ul>
				</div>

				<div className='grid grid-cols-1 gap-4 text-contents justify-start items-start'>
					<h3 className="font-bold text-lg">제품사진 기본 공지</h3>
					<ul className='list-disc list-inside pl-4'>
						<li>기본 수정 2회</li>
						<li>수정 추가 시 장당 5,000</li>
					</ul>
				</div>
			</>
		)
	}
]

export default Notices;
